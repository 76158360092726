import { MrDialog, PrivacyControls, Cookie } from '@mrhenry/wp--mr-interactive';

// Increase this number when you want the popup to reappear for all users.
const PRIVACY_ACCEPTANCE_GENERATION = 3;

class MrPrivacyDialog extends MrDialog {
	#enabledAtLoad : Set<string> = new Set();

	#controlValueChangeHandler = ( e: Event ) => {
		if ( e.target && ( e.target instanceof HTMLInputElement ) ) {
			const input = e.target;
			if ( input.closest( 'mr-privacy-dialog' ) === this ) {
				this.setAsSeen();

				return;
			}

			if ( input.hasAttribute( 'mr-prv-gtm-control' ) ) {
				this.updateState( 'close' );

				return;
			}
		}
	};

	override connectedCallback() {
		super.connectedCallback();

		this.setStateAtLoad();

		requestAnimationFrame( () => {
			if ( !this.hasSeen() ) {
				this.updateState( 'open' );

				return;
			}
		} );

		window.addEventListener( 'change', this.#controlValueChangeHandler );
	}

	override disconnectedCallback() {
		super.disconnectedCallback();

		window.removeEventListener( 'change', this.#controlValueChangeHandler );
	}

	override async updateState( directive: string ): Promise<void> {
		this.removeAttribute( 'show-preferences' );

		if ( 'close-and-accept' === directive ) {
			this.setAsSeen();
			this.accept();

			super.updateState( 'close' );

			this.pushNewlyEnabledToDataLayer();

			return;
		}

		if ( 'show-preferences' === directive ) {
			super.updateState( 'open' );
			this.setAttribute( 'show-preferences', '' );

			return;
		}

		if ( 'close-and-reject' === directive ) {
			this.setAsSeen();

			// It is theoretically possible that someone doesn't have the cookie anymore that tracks if the popup should be shown or not.
			// But still has cookies set to allow tracking.
			// We need to decline opt-ins explicitly to cover this case.
			// Else a user would click "Decline" but still be tracked because of old settings.
			this.declineOptIns();

			super.updateState( 'close' );

			return;
		}

		if ( 'close-and-save' === directive ) {
			this.setAsSeen();

			this.pushNewlyEnabledToDataLayer();

			super.updateState( 'close' );

			return;
		}

		super.updateState( directive );
	}

	hasSeen(): boolean {
		const match = document.cookie?.match( new RegExp( '(^| )komono_privacy_acceptance=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		const accepted = ( 0 < records.filter( ( record ) => {
			if ( 'se' !== record.key ) {
				return false;
			}

			const value = parseInt( record.value, 10 );
			if ( Number.isNaN( value ) ) {
				return false;
			}

			return PRIVACY_ACCEPTANCE_GENERATION <= value;
		} ).length );

		return accepted;
	}

	setAsSeen(): void {
		const match = document.cookie?.match( new RegExp( '(^| )komono_privacy_acceptance=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		// Because of cache multiple pages can have different versions of this code.
		// We should never lower this number.
		// Better to preserver the highest value.

		let currentGeneration = PRIVACY_ACCEPTANCE_GENERATION;
		records.forEach( ( record ) => {
			if ( 'se' !== record.key ) {
				return;
			}

			const value = parseInt( record.value, 10 );
			if ( Number.isNaN( value ) ) {
				return;
			}

			if ( value > currentGeneration ) {
				currentGeneration = value;
			}
		} );

		records = records.filter( ( record ) => {
			return 'se' !== record.key;
		} );

		records.push( {
			key: 'se',
			value: `${currentGeneration}`,
		} );

		const cookieExpiration = new Date();
		cookieExpiration.setTime( cookieExpiration.getTime() + 90 * 24 * 60 * 60 * 1000 );

		if ( window.Mr_Prv_GTM_Helpers.cookieDomain() ) {
			document.cookie = `komono_privacy_acceptance=${Cookie.packRecords( records )}; domain=${window.Mr_Prv_GTM_Helpers.cookieDomain()}; path=/; secure; samesite=lax; expires=` + cookieExpiration.toUTCString();
		} else {
			document.cookie = `komono_privacy_acceptance=${Cookie.packRecords( records )}; path=/; secure; samesite=lax; expires=` + cookieExpiration.toUTCString();
		}
	}

	accept(): void {
		// This is hard coded here for now.
		// Might be better if we can pull this from ACF

		// These are "Opt In"
		PrivacyControls.enableInGTM( 'fp' ); // Facebook pixel
		PrivacyControls.enableInGTM( 'pi' ); // Pinterest
		PrivacyControls.enableInGTM( 'tt' ); // TikTok
		PrivacyControls.enableInGTM( 'gr' ); // Google Remarketing

		// These are "Opt Out"
		PrivacyControls.enableInGTM( 'ga' ); // Google Analytics
	}

	declineOptIns(): void {
		// This is hard coded here for now.
		// Might be better if we can pull this from ACF

		// These are "Opt In"
		PrivacyControls.disableInGTM( 'fp' ); // Facebook pixel
		PrivacyControls.disableInGTM( 'pi' ); // Pinterest
		PrivacyControls.disableInGTM( 'tt' ); // TikTok
		PrivacyControls.disableInGTM( 'gr' ); // Google Remarketing
	}

	setStateAtLoad() {
		if ( PrivacyControls.isEnabledInGTM( 'ga' ) ) this.#enabledAtLoad.add( 'ga' );
		if ( PrivacyControls.isEnabledInGTM( 'fp' ) ) this.#enabledAtLoad.add( 'fp' );
		if ( PrivacyControls.isEnabledInGTM( 'pi' ) ) this.#enabledAtLoad.add( 'pi' );
		if ( PrivacyControls.isEnabledInGTM( 'tt' ) ) this.#enabledAtLoad.add( 'tt' );
		if ( PrivacyControls.isEnabledInGTM( 'gr' ) ) this.#enabledAtLoad.add( 'gr' );
	}

	enabledSinceLoad() {
		const changed: Set<string> = new Set();

		if ( PrivacyControls.isEnabledInGTM( 'ga' ) && !this.#enabledAtLoad.has( 'ga' ) ) changed.add( 'ga' );
		if ( PrivacyControls.isEnabledInGTM( 'fp' ) && !this.#enabledAtLoad.has( 'fp' ) ) changed.add( 'fp' );
		if ( PrivacyControls.isEnabledInGTM( 'pi' ) && !this.#enabledAtLoad.has( 'pi' ) ) changed.add( 'pi' );
		if ( PrivacyControls.isEnabledInGTM( 'tt' ) && !this.#enabledAtLoad.has( 'tt' ) ) changed.add( 'tt' );
		if ( PrivacyControls.isEnabledInGTM( 'gr' ) && !this.#enabledAtLoad.has( 'gr' ) ) changed.add( 'gr' );

		return changed;
	}

	pushNewlyEnabledToDataLayer() {
		const newlyEnabled = this.enabledSinceLoad();

		if ( newlyEnabled.has( 'ga' ) ) {
			window.dataLayer.push( {
				event: 'privacyConsent',
				ga: {
					event: {
						category: 'Privacy Consent',
						action: 'Accept',
					},
				},
			} );
		}

		if ( newlyEnabled.has( 'fp' ) ) {
			window.dataLayer.push( {
				event: 'privacyConsent FP',
				consentFor: 'fp',
				consentAction: 'accept',
			} );
		}

		if ( newlyEnabled.has( 'pi' ) ) {
			window.dataLayer.push( {
				event: 'privacyConsent PI',
				consentFor: 'pi',
				consentAction: 'accept',
			} );
		}

		if ( newlyEnabled.has( 'tt' ) ) {
			window.dataLayer.push( {
				event: 'privacyConsent TT',
				consentFor: 'tt',
				consentAction: 'accept',
			} );
		}

		if ( newlyEnabled.has( 'gr' ) ) {
			window.dataLayer.push( {
				event: 'privacyConsent GR',
				consentFor: 'gr',
				consentAction: 'accept',
			} );
		}
	}

	override firstFocusableElement(): HTMLElement | void {
		// override super.
		// keep focus on this currently active element.
		if ( document.activeElement && ( document.activeElement instanceof HTMLElement ) ) {
			return document.activeElement;
		}

		return super.firstFocusableElement();
	}
}

customElements.define( 'mr-privacy-dialog', MrPrivacyDialog );
