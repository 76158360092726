/* Types */
import '../types/mr-globals.ts';

/* Mr Interactive */
import { MrInputSink, PrivacyControls } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-input-sink', MrInputSink );

PrivacyControls.setupPrivacyAwareGTM();

window.Mr_Prv_GTM_Helpers.cookieDomain = () => {
	if ( -1 < location.hostname.indexOf( 'komono.com' ) ) {
		return 'komono.com';
	}

	return null;
};

/* Modules */
import '../modules/dots';
import '../modules/showcase-item-cta-full-click';

/* Tracking */
import '../modules/tracking/privacy-dialog';

/* New Stuff */
import '../modules/mr-accordion';
import '../modules/mr-fly-out-menu';
import '../modules/mr-fly-out-menu-trigger';
import '../modules/mr-navigation-overlay';
import '../modules/mr-klaviyo-newsletter-subscribe-popup';
